import React from "react";
import { Link } from "gatsby";
import Carousel from "react-bootstrap/Carousel";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import Image from "./Image";

const FrontDoors = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="vhodni_vrati/1.jpg"
              alt="Блиндирана входна врата"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="vhodni_vrati/2.jpg"
              alt="Блиндирана входна врата"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="vhodni_vrati/3.jpg"
              alt="Блиндирана входна врата"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
        </Carousel>
        <div className="row justify-content-center my-3">
          <Link className="btn btn-primary" to="/gallery/#vhodni_vrati" activeClassName="active">
            Към Галерията
          </Link>
        </div>
      </div>
      <section className="col-lg-6">
        <div>
          Предлагаме голям избор на блиндирани входни врати, които се характеризират със следното:
          <ul>
            <li>размери: 89 /200 за каса от 8,5 до 32 см</li>
            <li>покритие от декоративно или релефно фолио в цвят по избор</li>
            <li>изработени от 1.2мм ламарина, покрита с 0.8мм ламинирано ПДЧ</li>
            <li>50мм пълнеж от каменна вата за шумо- и топлоизолация</li>
            <li>бронирани ключалки</li>
            <li>регулируема каса с первази от двете страни за зид до 52см</li>
            <li>
              сигурни, благодарение на триточково заключване (2 брави с 6 активни шипа и 3 пасивни
              шипа от страна на пантите)
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

FrontDoors.propTypes = {
  frontmatter: PropTypes.object,
};

FrontDoors.defaultProps = {
  frontmatter: {},
};

export default FrontDoors;
